@import '../components/variables.scss';
@import '../components/datepicker-override.scss';

@font-face {
  font-family: "gotham-book";
  src: url('../assets/fonts/gotham-book.woff');
}

@font-face {
  font-family: "gotham-bold";
  src: url('../assets/fonts/gotham-bold.woff');
}


body {
  margin: 0;
  padding: 0;
	font-family: "gotham-book", "Montserrat", sans-serif;
}

h1 {
  font-size: 21px;
  margin: 20px 0;
  text-transform: uppercase;
  color: #18232f;
  font-weight: 700;
  letter-spacing: 2px;
}

// Grid
.row {
	float: left;
	width: 100%;
}

[class^="col-"] {
	float: left;
	margin-right: 1%;
}

.col-1 {
	width: 100%;
}

.col-1-2 {
	width: 49%;
	@media (max-width: $breakpoint-tablet-v) {
		width: 100%;
	}
}

.col-1-3 {
	width: 32.333333333333336%;
	@media (max-width: $breakpoint-tablet-v) {
		width: 100%;
	}
}

.col-2-3 {
	width: 65.66666666666667%;
	@media (max-width: $breakpoint-tablet-v) {
		width: 100%;
	}
}

.col-1-4 {
	width: 24%;
	@media (max-width: $breakpoint-tablet-v) {
		width: 100%;
	}
}

[class^="col-"]:last-child {
	margin-right: 0;
	&.col-1-3 {
		width: 33.333333333333336%;
		@media (max-width: $breakpoint-tablet-v) {
			width: 100%;
		}
	}
	&.col-1-2 {
		width: 50%;
		@media (max-width: $breakpoint-tablet-v) {
			width: 100%;
		}
	}
	&.col-1-4 {
		width: 25%;
		@media (max-width: $breakpoint-tablet-v) {
			width: 100%;
		}
	}
}

.mt-60 {
  margin-top: 60px;
}

.container {
	margin: 65px auto 10px auto;
	width: 100%;
	max-width: 1100px;
	padding: 20px;
	box-sizing: border-box;
	overflow: hidden;
	@media (max-width: $breakpoint-tablet-v) {
		padding: 20px 5.4%;
	}
	&.padding-bottom {
		padding-bottom: 200px;
	}
}

.main-content-link {

	opacity: 0;
	&:focus,
	&:focus-visible {
		visibility: visible;
		position: fixed;
		left: 0;
		opacity: 1;
		border: 1px solid rgb(16, 16, 16);
		z-index: 999;
	}
}
