@import '../../variables.scss';

// Header Component
.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background-color: white;
  border-bottom: solid 1px lighten(black, 87%);
  z-index: 2;
  .center {
    position: relative;
    margin: 0 auto 0 auto;
    height: 64px;
    width: 100%;
    max-width: 1220px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .logo {
    float: left;
    width: 140px;
    height: 64px;
    overflow: hidden;
    img {
      margin-top: 20px;
      display: block;
      width: 100%;

    }
    @media (max-width: $breakpoint-tablet) {
      margin-left: 8.1%;
    }
  }

  .logout {
    height: 64px;
    width: 125px;
    margin-left: 1.2rem;
    @media (max-width: $breakpoint-tablet) {
      display: none;
    }
    button {
      background: $blue !important;
      display: block;
      width: 100%;
      border: none;
      height: 30px;
      margin: 17px 0;
      text-align: center;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 1px;
      text-decoration: none;
      border-radius: $radius;
      color: white;
      line-height: 27px;
      cursor: pointer;
      outline: none;
      @include prefix(transition, 300ms opacity, webkit ms);
      &:hover {
        opacity: $hover-opacity;
      }
      &:focus {
        outline: 2px solid rgb(16, 16, 16);
        outline-offset: 2px;
      }
    }
  }

  .logout-without-language-selector {
    @extend .logout;
    margin-left: auto;
  }
}
