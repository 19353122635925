@import '../../../variables.scss';

.AutopaySegment {
    float: left;
    width: 100%;
    padding: 20px;
    border-radius: $radius;
    box-sizing: border-box;
    background-color: lighten(black, 97%);
    @media (max-width: $breakpoint-tablet-v) {
        margin: 50px 0;
    }
    h3 {
        color: #4D596B;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        @media (max-width: $breakpoint-tablet-v) {
            margin: 10px 0;
        }
        img {
            display: inline-block;
            vertical-align: bottom;
            margin: 0 4px 0 0;
            width: 13px;
        }
    }
    .navlink {
        border: none;
        display: block;
        margin: 10px auto;
        width: fit-content;
        padding: 16px 30px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
        border-radius: 4px;
        outline: none;
        -webkit-transition: 300ms opacity;
        -ms-transition: 300ms opacity;
        transition: 300ms opacity;
        text-decoration: none;
        text-align: center;
        color: white;
        background-color:$blue;

        &.border {
            border-color: $blue;
            border-style: solid;
        }

        &.invert {
            color: $blue;
            background-color: white;
            border-color: $blue;
            border-style: solid;
        }
        &.transparent {
            background-color: transparent;
        }
    }
    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0px 30px;
        @media (max-width: $breakpoint-tablet-v) {
            border-spacing: 0px 15px;
        }

        td {
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 10px 10px 0 10px;
            @media (max-width: $breakpoint-tablet-v) {
                padding: 0 10px 0 10px;
            }
            &:nth-child(2) {
                width: auto;
            }

            &:nth-child(2) {
                width: 100%;
                border-bottom: dashed 2px #D9D9D9;
            }

            h4 {
                color: $blue;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 12px;
                margin: 2px 0;
            }

            p {
                font-size: 12px;
                color: #4D596B;
                margin: 2px 0;
            }

            p.description {
                font-size: 12px;
                color: #4D596B;
                margin: 2px 0;
                white-space: normal;
            }

            a, button {
                color: $cyan;
                font-size: 12px;
                text-decoration: none;
                font-weight: 500;
            }
            button {
                background-color: transparent;
                border: none;
                cursor: pointer;
            }

            .price {
                font-size: 12px;
                color: black;
                font-weight: 600;
                text-align: right;
            }

            .red {
                color: #F25F5C;
            }
        }
    }
}
