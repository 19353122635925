@import '../../../variables.scss';

.LegalDocsChange {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(black, 0.60);
  @media (max-width: $breakpoint-tablet-v) {
    padding: 10px;
  }
  .modal-center {
    float: left;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: $radius-large;
    text-align: center;
    .modal-header {
      padding: 20px 0 0 40px;
      text-align: left;
    }
    .inner-modal-center {
      padding: 0 40px 40px;
      width: 100%;
      max-width: 600px;
      @media (max-width: $breakpoint-tablet-v) {
        box-sizing: border-box;
        padding: 10px;
      }
      .modal-text {
        font-size: 16px;
        color: #4D596B;
        font-weight: 300;
        margin: 40px 0;
        text-align: left;
        a {
          color: $blue;
          text-decoration: none;
        }
        .mt-40 {
          margin-top: 40px;
        }
      }
      .button-wrap {
        float: right;
        width: 100%;
        padding-bottom: 30px;
        button {
          display: inline-block;
          margin: 10px;
        }
      }
    }
  }
}
