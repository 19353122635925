@import '../../../variables.scss';

.OverviewPanel {
  display: flex;
  width: 100%;
  margin: 0 0 20px 0;
  border-radius: $radius;
  overflow: hidden;
  background-color: lighten(black, 97%);
  box-sizing: border-box;
  padding: 20px;
  @media (max-width: $breakpoint-tablet-v) {
    flex-direction: column;
  }
  .edit-profile-overview {
    width: 32.333333333333336%;
    min-height: 280px;
    margin-right: 2%;
    box-sizing: border-box;
    padding: 20px;
    border-radius: $radius;
    overflow: hidden;
    background-color: white;
    box-shadow: $shadow;
    display: flex;
    flex-direction: column;
    @media (max-width: $breakpoint-tablet-v) {
      width: 100%;
      margin: 0 0 20px 0;
    }
    .item {
      margin: 4px 0 20px 0;
      h4 {
        font-size: 12px;
        font-weight: 700;
        color: $blue;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 10px 0;
      }
      p {
        font-size: 14px;
        color: #4d596b;
        margin: 4px 0;
        &.email {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .edit-icon {
      position: absolute;
      cursor: pointer;
      text-align: center;
      color: #4d596b;
      align-self: flex-end;
      background: white;
      border: none;
    }
  }
  .order-status-overview {
    float: left;
    position: relative;
    display: flex;
    align-items: center;
    width: 65.66666666666667%;
    min-height: 280px;
    box-sizing: border-box;
    padding: 20px;
    border-radius: $radius;
    overflow: hidden;
    background-color: white;
    box-shadow: $shadow;
    @media (max-width: $breakpoint-tablet-v) {
      width: 100%;
      height: auto;
      flex-direction: column;
    }
    .icon-wrap {
      float: left;
      width: 50%;
      height: 100%;
      display: table;
      @media (max-width: $breakpoint-tablet-v) {
        width: 98%;
        height: auto;
        display: block;
      }
      .icon-center {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        width: 100%;
        @media (max-width: $breakpoint-tablet-v) {
          display: block;
        }
        .icon {
          width: 200px;
          margin: 0 auto;
          overflow: hidden;
          .circle {
            position: relative;
            display: inline-block;
            width: 95px;
            height: 95px;
            border-radius: 100%;
            border: solid 15px lighten(black, 98%);
            background-color: lighten(black, 95%);
            img {
              max-height: 100%;
              max-width: 100%;
              width: auto;
              height: auto;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
            .inactive {
              display: block;
            }
            .active {
              display: none;
            }
          }
          .label {
            display: inline-block;
            margin: 10px 0 0 0;
            h4 {
              font-size: 16px;
              letter-spacing: 1px;
              font-weight: 600;
              margin: 4px 0;
              color: #4d596b;
            }
          }
          &.active {
            .circle {
              border: solid 15px lighten($blue, 12%);
              background-color: lighten($blue, 1%);
              .inactive {
                display: none;
              }
              .active {
                display: block;
              }
            }
          }
        }
      }
    }
    .details-wrap {
      float: left;
      width: 50%;
      height: 100%;
      display: table;
      @media (max-width: $breakpoint-tablet-v) {
        width: 98%;
        margin: 20px 0;
        display: block;
        height: auto;
      }
      .details-center {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        width: 100%;
        @media (max-width: $breakpoint-tablet-v) {
          display: block;
        }
        .details {
          display: inline-block;
          text-align: left;
          position: relative;
          @media (max-width: $breakpoint-tablet-v) {
            text-align: center;
          }
          .details-item {
            margin: 4px 0 20px 0;
            h4 {
              font-size: 12px;
              font-weight: 700;
              color: $blue;
              text-transform: uppercase;
              letter-spacing: 1px;
              margin: 10px 0;
            }
            p {
              max-width: 200px;
              font-size: 14px;
              color: #4d596b;
              margin: 4px 0;
              white-space: nowrap;
              overflow: hidden;
              &.success-message {
                color: $blue;
                text-overflow: initial;
                white-space: initial;
                margin: 0 0 9px 0;
              }
              &.error-message {
                color: $red;
                text-overflow: initial;
                white-space: initial;
                margin: 0 0 9px 0;
              }
              &.shipping-date-disclaimer {
                white-space: normal;
                font-size: 11px;
              }
            }
            .tracking-number-link {
              color: #4d596b;
              text-decoration: underline;
            }
            .edit-icon {
              margin: 10px;
              cursor: pointer;
              background: white;
              border: none;
            }
            .cancel-order-action {
              cursor: pointer;
              background: white;
              border: none;
            }
            .danger-text {
              color: #f25f5c;
              text-decoration: none;
              font-weight: bold;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .date-picker-footer {
    width: 100%;
    display: table;
    p {
      display: table-caption;
      margin: 10px 45px;
      // margin: 5px 45px;
    }
  }

  .order-status-overview-error {
    float: right;
  }
}
