@import '../../variables.scss';
.Checkbox {
	display: block;
	position: relative;
	padding-left: 26px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 14px;
	line-height: 18px;
	text-align: left;
	color: #4D596B;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked ~ .checkmark {
			background-color: $blue;
			&:after {
				display: block;
			}
		}
	}
	.checkmark {
		position: absolute;
		border-radius: 2px;
		top: 0;
		left: 0;
		height: 14px;
		width: 14px;
		background-color: transparent;
		border: solid 2px black;
		transition: 300ms all;
		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 4px;
			top: 0;
			width: 4px;
			height: 8px;
			border: solid white;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}
