@import '../../../variables.scss';

.MainMenu {
  position: absolute;
  left: 150px;
  display: flex;
  align-items: center;
  height: 64px;
  @media (max-width: $breakpoint-tablet) {
    display: none;
  }
  ul {
    margin: 22px 10px;
    padding: 0;
    button {
      background: transparent;
      cursor: pointer;
      border: none;
      display: inline-block;
      outline: none;
      line-height: 20px;
      font-size: 15px;
      letter-spacing: 1px;
      line-height: 20px;
      height: auto;
      padding: 8px 12px;
      border-radius: 100px;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      color: #354256;
    }
    li {
      display: inline-block;
      a {
        display: inline-block;
        height: 20px;
        padding: 0 10px;
        font-size: 10px;
        letter-spacing: 1px;
        line-height: 20px;
        border-radius: 10px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        color: #354256;
        &.active {
          color: white;
          background-color: $blue;
        }
      }
    }
  }
}
