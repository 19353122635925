@import '../../variables.scss';

.Radio {
  .radio-item {
    display: block;
    position: relative;
    padding-left: 24px;
    margin: 20px 0;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    label {
      cursor: pointer;
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked ~ .checkmark {
        background-color: $blue;
        border: solid 2px $blue;
      }
      &:checked ~ .checkmark:after {
        display: block;
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 14px;
      width: 14px;
      background-color: transparent;
      border: solid 2px gray;
      border-radius: 50%;
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
      &:after {
        top: 5px;
        left: 5px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: white;
      }
    }
  }
}
