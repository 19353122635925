@import '../../variables';

.Button {
	border: none;
	display: block;
	margin: 10px 0;
	width: fit-content;
	padding: 16px;
	cursor: pointer;
	font: inherit;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 1px;
	border-radius: $radius;
	outline: none;
	@include prefix(transition, 300ms opacity, webkit ms);
	text-decoration: none;
	text-align: center;
	&.full-width {
		padding: 16px 0;
		width: 100%;
	}
	&.in-center {
		margin: 10px auto;
	}
	&.large {
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 16px;
		padding: 16px 50px;
	}
	&.red {
		color: white;
		background-color: $red;
	}
	&.red-inverted {
		background: white;
		color: $red;
		box-shadow: inset 0px 0px 0px 2px $red;
  }
	&.green {
		color: white !important;
		background-color: $blue;
	}
	&.green-inverted {
		background: white;
		color: $blue;
		box-shadow: inset 0px 0px 0px 2px $blue;
	}
	&.gray {
		background: white;
		color: $red;
		box-shadow: inset 0px 0px 0px 2px $red;
	}
	&.disabled {
		color: white;
		opacity: 0.5;
		cursor: default;
		&:hover {
			opacity: 0.7;
		}
	}
	&.full-width {
		width: 100%;
	}
	&:hover {
		opacity: $hover-opacity;
	}
	&:focus {
		outline: 1px solid rgb(16, 16, 16);
	}
	&.pull-right {
		float: right;
	}
}
