@import '../../../variables.scss';

.BillHistoryTable {
  .bill-table {
    border-collapse: collapse;
    width: 100%;
    th,
    td {
      text-align: left;
      font-size: 14px;
      color: #4D596B;
      font-weight: 500;
      height: 48px;
      border-bottom: solid 1px #D9D9D9;
      @media (max-width: $breakpoint-tablet-v) {
        font-size: 11px;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
      }
    }
    tr {
      &.collapse {
        display: none;
      }
      &.strong {
        td {
          font-weight: bold;
        }
      }
    }
    th {
      color: $blue;
      font-weight: 600;
    }
    td {
      white-space: nowrap;
      div {
        text-align: right;
        padding-right: 10px;
      }
    }
    .header-description {
      width: 38%
    }
    .header-period {
      width: 40%
    }
    .header-amount {
      width: 15%
    }
    .arrow {
      position: relative;
      width: 40px;
      text-align: center;
      padding: 22px;
      @media (max-width: $breakpoint-tablet-v) {
        padding: 11px;
      }
      &::after {
        position: absolute;
        width: 6px;
        height: 6px;
        content: "";
        left: 18px;
        border: solid #82888E;
        border-width: 0 2px 2px 0;
        top: 22px;
        -webkit-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        transform: rotate(225deg);
        @media (max-width: $breakpoint-tablet-v) {
          left: 9px;
        }
      }
      &.close {
        &::after {
          top: 18px;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
    .head {
      cursor: pointer;
      td {
        background-color: #FAFBFC;
      }
      .amount {
        font-weight: 600;
        color: black;
      }
      .red {
        color: #F25F5C;
      }
    }
    .sub-head {
      cursor: pointer;
    }
    tr {
      td:nth-child(2) {
        text-indent: 20px;
        @media (max-width: $breakpoint-tablet-v) {
          text-indent: 0;
        }
      }
    }
    tr.sub-head {
      td:nth-child(2) {
        text-indent: 10px;
        @media (max-width: $breakpoint-tablet-v) {
          text-indent: 0;
        }
      }
    }
    tr.head {
      td:nth-child(2) {
        text-indent: 0px;
      }
    }
  }
  .total-table {
    margin: 40px 0 0 0;
    border-collapse: collapse;
    width: 100%;
    td {
      white-space: nowrap;
      div {
        text-align: right;
        padding-right: 10px;
      }
    }
    h5 {
      font-size: 14px;
      font-weight: 600;
      margin: 2px 0;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      margin: 2px 0;
      color: #82888E;
    }
    .amount {
      text-align: right;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .past-due {
    color: #F25F5C;
  }
  .past-due-text{
    float: right;
  }
  .upcoming-amount {
    color:#adadad;
  }

  .download-image-column {
   display: flex;
   justify-content: center;
   align-items: center;
   .download-image {
       width: 16px;
       height: 16px;
     }
  }
}
