@import '../../../variables.scss';

.MobileMainMenu {
  position: fixed;
  top: 0;
  right: 0;
  display: none;
  background:transparent;
  z-index: 999;
	display: none;
	@media (max-width: $breakpoint-tablet) {
		display: block;
	}
  &.open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
		z-index: 999;
		display: block;
		overflow: auto;
  }
  .burger-menu {
		float: right;
		width: 40px;
		height: 40px;
		line-height: 40px;
		margin: 12px 20px 12px 12px;
		cursor: pointer;
		text-align: center;
		.menu-icon {
			font-size: 22px;
			line-height: 40px;
			color: lighten(black, 30%);
			display: inline-block;
      vertical-align: middle;
      margin: 7px 0;
		}
	}
	ul {
    float: left;
    width: 100%;
    display: block;
		margin: 22px 0;
		padding: 0;
		button {
			background: transparent;
			cursor: pointer;
			border: none;
			display: inline-block;
			outline: none;
			line-height: 20px;
			font-size: 15px;
			letter-spacing: 1px;
			line-height: 20px;
			height: auto;
			padding: 6px 12px;
			border-radius: 100px;
			text-decoration: none;
			text-transform: uppercase;
			font-weight: 600;
			color: #354256;
		}
		li {
      display: block;
      text-align: center;
			a, span {
				display: inline-block;
				font-size: 10px;
				letter-spacing: 1px;
				line-height: 20px;
				text-decoration: none;
				text-transform: uppercase;
				font-weight: 600;
        color: #354256;
        margin: 6px;
        height: auto;
        padding: 6px 12px;
				border-radius: 100px;
				cursor: pointer;
				&.active {
					color: white;
					background-color: $blue;
				}
			}
		}
	}
}
