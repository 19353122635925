@import '../../../variables.scss';

.PaymentPlanTable {
    overflow-x: auto;
    .payment-plan-table {
      border-collapse: collapse;
      width: 70%;
      th,
      td {
        text-align: center;
        font-size: 14px;
        color: #4D596B;
        font-weight: 500;
        height: 48px;
        border-bottom: solid 1px #D9D9D9;
        @media (max-width: $breakpoint-tablet-v) {
          font-size: 11px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap
        }
      }
      tr {
        &.collapse {
          display: none;
        }
        &.strong {
          td {
            font-weight: bold;
          }
        }
      }
      th {
        color: $blue;
        font-weight: 600;
      }
      td {
        white-space: nowrap;
        div {
          text-align: right;
          padding-right: 10px;
        }
      }
      .header-month {
        width: 30%
      }
      .header-amount {
        width: 38%
      }
      .arrow {
        position: relative;
        width: 40px;
        text-align: center;
        &::after {
          position: absolute;
          width: 6px;
          height: 6px;
          content: "";
          left: 18px;
          border: solid #82888E;
          border-width: 0 2px 2px 0;
          top: 22px;
          -webkit-transform: rotate(225deg);
          -ms-transform: rotate(225deg);
          transform: rotate(225deg);
        }
        &.close {
          &::after {
            top: 18px;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      .head {
        cursor: pointer;
        td {
          background-color: #FAFBFC;
        }
        .amount {
          font-weight: 600;
          color: black;
        }
        .red {
          color: #F25F5C;
        }
      }
      .sub-head {
        cursor: pointer;
      }
      tr {
        td:nth-child(2) {
          padding-right: 20px;
          @media (max-width: $breakpoint-tablet-v) {
            text-indent: 0;
          }
        }
        td:nth-child(3) {
          padding-right: 20px;
        }
      }
      tr.sub-head {
        td:nth-child(2) {
          text-indent: 10px;
          @media (max-width: $breakpoint-tablet-v) {
            text-indent: 0;
          }
        }
      }
      tr.head {
        td:nth-child(2) {
          text-indent: 0px;
        }
      }
    }
}
