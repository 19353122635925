@import '../../../variables.scss';

.BillHistoryForm {
  .insurance-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: -10px;
  }

  @media (max-width: $breakpoint-tablet-v) {
    .Select {
      margin: 5px 0;
    }
    .col-2-3 {
      .row {
        overflow-x: visible;
      }
    }
  }
}
