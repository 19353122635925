@import '../../../variables.scss';

.ReferralsForm {
  float: left;
  width: 100%;
  background-color: lighten(black, 97%);
  padding: 20px;
  border-radius: $radius;
  box-sizing: border-box;
  .balance-form-inner {
    float: left;
    width: 100%;
    overflow: hidden;
    background-color: white;
    text-align: center;
    vertical-align: middle;
    box-shadow: $shadow;
    border-radius: $radius;
    .inner-cenrer{
      display: inline-block;
      width: 500px;
      @media (max-width: $breakpoint-tablet-v) {
        display: block;
        width: 100%;
      }
    }
    .icon-wrap {
      margin: 20px 0;
      width: 115px;
      display: inline-block;
      .icon {
        position: relative;
        display: inline-block;
        width: 85px;
        height: 85px;
        border-radius: 100%;
        border: solid 15px lighten($blue, 12%);
        background-color: lighten($blue, 1%);
        img {
          max-height: 100%;
          max-width: 100%;
          width: auto;
          height: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
      .label {
        display: inline-block;
        margin: 10px 0 0 0;
        h4 {
          font-size: 16px;
          letter-spacing: 1px;
          font-weight: 600;
          margin: 4px 0;
          color: $blue;
        }
        p {
          margin: 4px 0;
          color: #4D596B;
          font-size: 14px;
        }
      }
    }
    .message-wrap {
      width: 380px;
      display: inline-block;
      @media (max-width: $breakpoint-tablet-v) {
        display: block;
        width: 90%;
        margin: 0 5%;
      }
    }
    .message-wrap .active {
      margin: 20px 0;
    }
    .form {
      margin: 20px 0;
      width: 380px;
      display: inline-block;
      @media (max-width: $breakpoint-tablet-v) {
        display: block;
        width: 100%;
      }
      .input-wrap {
        float: left;
        width: 260px;
        margin-right: 10px;
        @media (max-width: $breakpoint-tablet-v) {
          width: 90%;
          margin: 0 5%;
        }
      }
      .button-wrap {
        float: left;
        width: 110px;
        @media (max-width: $breakpoint-tablet-v) {
          width: 90%;
          margin: 0 5%;
        }
        .Button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .col-2-3 {
    .row {
      overflow-x: auto;
    }
  }
}

.lighter-text {
  color: #4d596b;
}

.nav-text {
  color: $blue;
  text-decoration: none;
  font-weight: bold;
}

.referrals-list-heading {
  font-size: 17px;
  color: $blue;
  padding: 0;
  margin-bottom: 20px;
  margin-top: 30px;
}

.message-wrap div {
  width: 100%;
}
