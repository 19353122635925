@import '../../../variables.scss';

.RequestDataExportSuccessModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(black, 0.60);
    padding: 10px;
  .modal-center {
    float: left;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: $radius-large;
    text-align: center;
    max-width: 600px;
    padding: 20px;
    .inner-modal-center {
      max-width: 600px;
      box-sizing: border-box;
      padding: 10px;
      .icon-wrap {
        width: 100%;
        overflow: hidden;
        .icon {
          position: relative;
          display: inline-block;
          width: 85px;
          height: 85px;
          border-radius: 100%;
          border: solid 15px lighten($blue, 12%);
          background-color: lighten($blue, 1%);
          img {
            max-height: 100%;
            max-width: 100%;
            width: auto;
            height: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }
      .modal-text {
        font-size: 16px;
        color: #4D596B;
        font-weight: 300;
        margin: 20px 10px 40px;
        text-align: left;
        display: flex;
        flex-direction: column;
        a {
          color: $blue;
          text-decoration: none;
        }
      }
      .button-wrap {
        float: left;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        button {
          display: inline-block;
          margin: 10px;
        }
      }
    }
  }
}
