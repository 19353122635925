@import '../../../variables.scss';

.EditAddressModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(black, 0.60);
  @media (max-width: $breakpoint-tablet-v) {
    padding: 10px;
  }
  .modal-center {
    float: left;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: $radius-large;
    min-height: 500px;
    text-align: center;
    .inner-modal-center {
      padding: 20px;
      width: 100%;
      max-width: 600px;
      @media (max-width: $breakpoint-tablet-v) {
        box-sizing: border-box;
        padding: 10px;
      }
      .icon-wrap {
        width: 100%;
        overflow: hidden;
        .icon {
          position: relative;
          display: inline-block;
          width: 85px;
          height: 85px;
          border-radius: 100%;
          border: solid 15px lighten($blue, 12%);
          background-color: lighten($blue, 1%);
          @media (max-width: $breakpoint-tablet-v) {
            width: 55px;
            height: 55px;
          }
          img {
            max-height: 100%;
            max-width: 100%;
            width: auto;
            height: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }
      h3 {
        font-family: "gotham-bold";
        font-size: 21px;
        margin: 20px 0;
        text-transform: uppercase;
        color: #18232f;
        font-weight: 700;
        letter-spacing: 2px;
        @media (max-width: $breakpoint-tablet-v) {
          margin: 2px;
          font-size: 17px;
        }
      }
      .modal-text {
        font-size: 16px;
        color: #4D596B;
        font-weight: 300;
        margin: 40px 10px;
        @media (max-width: $breakpoint-tablet-v) {
          margin: 4px 10px;
        }
        .Input, .Select {
          @media (max-width: $breakpoint-tablet-v) {
            margin: 2px 0;
          }
        }
        .AddressForm {
          .input-row {
            float: left;
            width: 100%;
            overflow: auto;
            @media (max-width: $breakpoint-tablet-v) {
              max-height: 230px;
            }
          }
        }
      }
      .modal-errors {
        display: inline-block;
      }
      .button-wrap {
        float: left;
        width: 100%;
        button {
          display: inline-block;
          margin: 10px;
        }
      }
    }
  }
}
