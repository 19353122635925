@import '../../../variables.scss';

.ProductSupportModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(black, 0.60);
  @media (max-width: $breakpoint-tablet-v) {
    padding: 10px;
  }
  .modal-center {
    float: none;
    position: relative;
    padding: 35px;
    padding-top: 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: $radius-large;
    max-height: 90vh;
    overflow-y: auto;
    text-align: center;
    max-width: 500px;

    @media (max-width: 500px) {
      folat: none;
    }

    .header-wrapper {
      display:flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;

      .close-modal {
        margin-left: auto;
        cursor: pointer;
      }
    }
    .error-message {
      color: red;
      font-size: 12px;
      margin-top: 5px;
      text-align: start;
    }
    .modal-text {
      font-size: 16px;
      text-align: start;
      margin-bottom: 10px;
      margin-top: 5px;
      font-weight: 325;
      line-height: 24px;

      &.text-center {
        text-align: center;
      }
    }
    .file-btn {
      border: none;
      background: $blue;
      padding: 10px 20px;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
      transition: background .2s ease-in-out;
    }

    .file-btn-disabled {
      border: 1px solid $blue;
      background: #fff;
      padding: 10px 20px;
      border-radius: 10px;
      color: $blue;
      cursor: not-allowed;
      transition: background .2s ease-in-out;
    }

    .drop-container {
      position: relative;
      display: flex;
      gap: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px;
      border-radius: 12px;
      border: 2px dashed #737373;
      color: #444;
      cursor: pointer;
      transition: background .2s ease-in-out, border .2s ease-in-out;

      &.disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }

      &.drag-over {
        background: #eee;
        border-color: #111;
      }

      .dropped-files {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: red;
        flex-direction: column;
        .dropped-files-list {
          overflow: scroll;
        }
      }
    }

    .drop-container:hover {
      background: #eee;
      border-color: #111;
    }

    .drop-container:hover .drop-title {
      color: #222;
    }

    .drop-title {
      color: #444;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      transition: color .2s ease-in-out;
    }

    .note {
      font-size: 12px;
      color: #737373;
      text-align: start;
    }

    .file-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border: 1px solid #D4D4D8;
      border-radius: 12px;
      margin-top: 10px;

      .file-name {
        display: flex;
        align-items: center;
        overflow-y: hidden;
        .file-icon {
          width: 20px;
          height: 20px;
        }
      }

      .remove-file {
        cursor: pointer;
      }
    }

    .line-items {
      margin-bottom: 40px;
      .line-item {
        display: flex;
        padding: 12px 16px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid #D4D4D8;
        margin-bottom: 10px;

        &.disabled {
          background: #dddddd42;
          opacity: 0.7;
        }

        &.active {
          border: 1px solid #737373;
        }

        .item-name {
          padding-left: 26px;
          font-weight: 350;
          margin-top: 0;
        }
        .serial-number {
          padding-left: 26px;
          font-size: 12px;
          font-style: normal;
          color: #737373;
          margin-top: 0;
          text-align: start;
        }
        .note {
          padding-left: 26px;
          font-weight: 350;
          font-size: 12px;
          margin-top: 0;
          color: #737373;
          text-align: justify;
          .link {
            color: $blue;
          }
        }
        .item-quantity-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .item-quantity-input {
            width: 100px;
            margin-left: 26px;
            border: solid 1px #e8e8e8;
            padding: 8px 4px;
            text-align: center;
            border-radius: $radius;
            box-sizing: border-box;

            &:focus-visible {
              outline: 1px solid $blue
            }
          }

          .item-price {
            font-size: 12px;
            color: #0A0A0A;
            font-weight: 325;
          }
        }
      }
    }

    .shipping-address-wrapper {
      max-width: 550px;
      display: flex;
    }
    .error-message-wrapper {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }

    .button-wrap {
      clear: both;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      button {
        display: inline-block;
      }

      &.center {
        justify-content: center;
      }
    }
  }
}
