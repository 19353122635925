@import '../../../variables.scss';


.UsageSummaryTable {
  float: left;
  width: 100%;
  .bill-table {
    margin: 20px 0;
    border-collapse: collapse;
    width: 100%;
    th, td {
      font-size: 14px;
      text-align: left;
      height: 48px;
      color: #4D596B;
      font-weight: 500;
      border-bottom: solid 1px #D9D9D9;
    }
    th:nth-child(1) {
      width: 40%;
      @media (max-width: $breakpoint-tablet-v) {
				width: auto;
			}
    }
    th:nth-child(3) {
      width: 20%;
      text-align: right;
      @media (max-width: $breakpoint-tablet-v) {
				width: auto;
			}
		}
    th {
      padding: 0 10px;
      color: #82888E;
      font-weight: 700;
    }
    tr td {
      padding: 0 10px;
      background-color: #FAFBFC;
    }
    td:nth-child(3) {
      text-align: right;
    }
  }
  .total-table {
    margin: 20px 0;
    border-collapse: collapse;
    width: 100%;
    td {
      padding: 10px;
      &.currency {
        text-align: right;
      }
    }
    h4 {
      color: $blue;
      margin: 4px 0px 4px 4px;
      font-size: 18px;
    }
  }
}
