.company-logo {
    height: 90px;
 }
.benefits {
    flex-grow: 1;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    color: #4d596b;
    font-size: 14px;
    box-shadow: 0 0 1px 2px #f2f2f2;
    padding: 20px;
    border-radius: 4px;
    background-color: #f7f7f7;

    &__col {
        flex: 1;
        box-shadow: 0 0 1px 2px #f2f2f2;
        background: white;
        padding: 15px;
        border-radius: 4px;
        h4 {
            font-weight: bold;
            text-shadow: 0 0 black;
        }
        ul {
            margin-left: -27px;
            font-weight: lighter;
            li {
                margin-top: 5px;
            }
        }
    }
}

.benefits__description {
    flex-grow: 1;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    color: #4d596b;
    font-size: 14px;
    box-shadow: 0 0 1px 2px #f2f2f2;
    padding: 20px 20px 0 20px;
    border-radius: 4px 4px 0 0;
    background-color: #f7f7f7;

    &__col {
        flex: 1;
        box-shadow: 0 0 1px 2px #f2f2f2;
        background: white;
        padding: 15px;
        border-radius: 4px;
        h4 {
            font-weight: bold;
            text-shadow: 0 0 black;
        }
        div {
            margin-left: -27px;
            font-weight: lighter;
            margin-top: 5px;
        }
    }
}
