@import '../../variables.scss';

.Select {
	user-select: none;
  position: relative;
  width: 100%;
  &:focus-visible {
    outline: none;
  }
  button {
    width: 100%;
    font-family: inherit;
    font-size: inherit;
  }
  .select-header {
    align-items: center;
    height: 48px;
    justify-content: space-between;
    line-height: 48px;
    border: 1px solid #E8E8E8;
    border-radius: $radius;
    position: relative;
    background-color: white;
    cursor: pointer;
    &.disabled {
      cursor: default;
      background: #D8D8D8;
    }
  }
  .placeholder {
    position: absolute;
    top: 2px;
    left: 12px;
    font-size: 10px;
    line-height: 18px;
    color: #82888e;
    &.large-placeholder {
      top: 12px;
      left: 12px;
      height: 24px;
      line-height: 24px;
      font-size: 13px;
      color: #82888e;
      transition: all 150ms;
    }
  }
  .header-title {
    margin: 6px 20px 2px 12px;
    margin-right: 30px;
    font-weight: 500;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}
  .angle-down {
		position: absolute;
    top: 18px;
    right: 16px;
    width: 8px;
    height: 8px;
    border: solid #82888E;
    border-width: 2px 0 0 2px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .angle-up {
		position: absolute;
    top: 16px;
    right: 16px;
    width: 8px;
    height: 8px;
    border: solid #82888E;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .select-row {
    position: relative;
    float: left;
    width: 100%;
    .select-left-cell {
      float: left;
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .select-right-cell {
      float: left;
    }
  }
  .select-list {
		z-index: 1;
    position: absolute;
    width: 100%;
    border: 1px solid rgb(223, 223, 223);
    border-top: none;
    border-radius: $radius;
    border-bottom-left-radius: 3px;
    background-color: white;
    box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
    font-weight: 700;
    padding: 15px 0;
    max-height: 215px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
	}
  .select-list-item {
    box-sizing: border-box;
		width: 100%;
    padding: 16px 10px;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 13px;
    &.selected {
			color: black;
      background-color: #f7f7f7;
		}
    &:hover {
      color:black;
      background-color: #f7f7f7;
		}
	}

}
