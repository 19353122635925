@import '../../../variables.scss';

.ProfileForm {
  margin-block-end: 2em;
  overflow: hidden;
  .checkbox-wrap {
    margin-top: 14px;
  }
  .button-wrap {
    margin-top: 16px;
  }

  .note {
    font-size: 10px;
    color: $mid-gray;
    margin-top: -5px;
  }
}
