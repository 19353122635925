@import '../../../variables.scss';

.ProductPanel {
  float: left;
  width: 100%;
  margin-bottom: -20px;
  border-radius: $radius;
  background-color: lighten(black, 97%);
  position: relative;
  .green {
    background: #58b4b8 !important;
  }
  .inner-wrap {
    background-color: white;
    margin: 20px;
    border-radius: $radius;
    overflow: hidden;
    box-shadow: $shadow;
    .heading {
      width: 100%;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
      @media (max-width: $breakpoint-tablet-v) {
        flex-direction: column;
        img {
          margin-left: auto;
        }
      }
      span {
        float: left;
        border-radius: 100px;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        padding: 10px 20px;
        margin: 10px;
        letter-spacing: 1px;
        background-color: lighten(black, 91%);
        cursor: pointer;
        &.order.active {
          cursor: default;
          background-color: $blue;
        }
        &.snoo.active {
          cursor: default;
          background-color: $blue;
        }
      }
    }
    .body {
      position: relative;
      float: left;
      width: 100%;

      .order-number-wrapper {
        position: absolute;
        bottom: 0;
        padding-left: 16px;
        font-size: 12px;
        .text-primary {
          color: $blue;
        }
      }

      .circle-center {
        position: relative;
        overflow: hidden;
        text-align: center;
        font-size: 0;
        .item {
          position: relative;
          display: inline-block;
          width: 115px;
          margin: 10px 60px;
          font-size: 16px;
          @media (max-width: $breakpoint-tablet-v) {
            display: none;
          }
          .circle {
            position: relative;
            float: left;
            width: 85px;
            height: 85px;
            border-radius: 100%;
            border: solid 15px lighten(black, 98%);
            background-color: lighten(black, 95%);
            img {
              max-height: 100%;
              max-width: 100%;
              width: auto;
              height: auto;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
            .inactive {
              display: block;
            }
            .active {
              display: none;
            }
            &::before {
              position: absolute;
              top: 42px;
              right: 100px;
              width: 60px;
              height: 4px;
              content: "";
              background-color: lighten(black, 95%);
            }
            &::after {
              position: absolute;
              top: 42px;
              left: 100px;
              width: 60px;
              height: 4px;
              content: "";
              background-color: lighten(black, 95%);
            }
          }
          .label {
            float: left;
            width: 115px;
            height: 60px;
            margin: 10px 0 0 0;
            h4 {
              font-size: 9px;
              letter-spacing: 1px;
              font-weight: 700;
              margin: 4px 0;
              text-transform: uppercase;
            }
            h5 {
              font-size: 10px;
              letter-spacing: 1px;
              font-weight: 500;
              margin: 4px 0;
              text-transform: uppercase;
            }

            @media (max-width: $breakpoint-tablet) {
              margin-bottom: 40px;
            };
          }
          &:first-child {
            margin-left: 0;
            .circle {
              &::before {
                display: none;
              }
            }
          }
          &:last-child {
            margin-right: 0;
            .circle {
              &::after {
                display: none;
              }
            }
          }
          &.active {
            .inactive {
              display: none;
            }
            .active {
              display: block;
            }
            @media (max-width: $breakpoint-tablet-v) {
              display: inline-block;
              margin: 0;
            }
            .circle {
              border: solid 15px lighten($blue, 12%);
              background-color: lighten($blue, 1%);
              &::after {
                background: linear-gradient(to right, $blue 0%, $blue 20%, lighten(black, 95%) 25%, lighten(black, 95%) 100%);
                @media (max-width: $breakpoint-tablet-v) {
                  display: none;
                }
              }
              &::before {
                background: linear-gradient(to right, lighten(black, 95%) 0%, lighten($blue, 20%) 100%);
                @media (max-width: $breakpoint-tablet-v) {
                  display: none;
                }
              }
            }
            .label {
              h4, h5 {
                color: $blue;
              }
            }
          }
        }
      }

    }
  }
  .outside-message {
    position: absolute;
    top: -70px;
    right: 0;
    padding: 10px 20px;
    background: #93C6CC;
    border-radius: 100px;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 14px;
    @media (max-width: $breakpoint-tablet-v) {
      display: none;
    }
  }
}
