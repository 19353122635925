@use '../../../variables.scss';
.AddressForm {
  .Select {
    margin: 10px 0;
    background-color: transparent;
    border: 0;
    &:focus-visible {
      outline: none;
      .select-header {
        outline: 1px solid;
      }
    }
  }
}
