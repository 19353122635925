@use '../../../variables.scss';

.InitiateReturnForm {
  .button-wrap {
    button, a {
      display: inline-block;
      margin-right: 20px;
    }
  }
  p {
    font-size: 16px;
    margin: 20px 0;
    color: #4D596B;
  }
}
