@import '../../variables.scss';

.stepper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  .circle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .circle {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #ddd;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &.active {
        background-color: $blue;
      }
    }
    .step-heading {
      position: absolute;
      margin-top: 80px;
      color: #ddd;
      &.active {
        color: #333333;
      }
    }
  }


  .line {
    height: 2px;
    max-width: 150px;
    background-color: #ddd;

    &.active {
      background-color: $blue;
    }
  }
}
