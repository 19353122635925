@import '../../../variables.scss';

.CreditCardForm {
  float: left;
  width: 100%;
  margin: 0 0 30px 0;
  .stripe-form {
    float: left;
    width: 100%;
    margin: 10px 0;
    overflow: hidden;
    border-radius: $radius;
    border: solid 1px #e8e8e8;
    background-color: #fafafa;
    .form-heading {
      float: left;
      width: 100%;
      height: 40px;
      overflow: hidden;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 425px) {
        flex-direction: column;
        height: 80px;
      }
      .form-heading-left {
        width: 20%;
        height: 40px;
        line-height: 40px;
        @media (max-width: 425px) {
          width: 100%;
        }
        h5 {
          margin: 0 10px;
          font-size: 14px;
        }
      }
      .form-heading-right {
        flex: 1 1 50%;
        height: 40px;
        line-height: 40px;
        text-align: right;
        @media(max-width: 425px) {
          text-align: left;
        }
        .card {
          display: inline-block;
          height: 24px;
          width: 38px;
          margin: 2px;
          vertical-align: middle;
          margin: 0 10px 0 0;
          border-radius: 3px;
          overflow: hidden;
          background-repeat: no-repeat;
          background-size: contain;
        }
        .text {
          font-size: 11px;
          margin: 2px 16px 2px 2px;
          color: $mid-gray;
        }
      }
    }
    .form-body {
      padding: 10px;
    }
  }
  .button-wrap {
    .Button {
      display: inline-block;
      margin: 10px 10px 10px 0;
      @media (max-width: $breakpoint-tablet-v) {
        width: 100%!important;
        display: block
      }
    }
  }

  .stripe-input {
    float: left;
    position: relative;
    margin: 10px 0;
    height: 49px;
    border-radius: 4px;
    overflow: hidden;
    border: solid 1px #e8e8e8;
    width: 100%;
    background-color:white;
    box-sizing: border-box;
    cursor: text;
    text-align: left;
    label {
      position: absolute;
      top: 12px;
      left: 12px;
      height: 18px;
      line-height: 18px;
      font-size: 14px;
      color: #82888e;
      transition: all 150ms;
      &.focus {
        top: 2px;
        font-size: 10px;
      }
    }
    .input-wrap {
      margin: 20px 0 0 8px;
    }
    .stripe-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      color: $mid-gray;
    }
  }

  .sub-header {
    font-size: 14px;
    color: #4D596B;
  }
}
