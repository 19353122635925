@import '../../../variables.scss';

.InitiateReturnModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(black, 0.60);
  @media (max-width: $breakpoint-tablet-v) {
    padding: 10px;
  }
  .modal-center {
    float: left;
    position: relative;
    padding: 35px;
    padding-top: 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: $radius-large;
    max-height: 90vh;
    overflow-y: auto;
    text-align: center;
    max-width: 500px;
    .header-wrapper {
      display:flex;
      justify-content: space-between;
      align-items: center;

      .close-modal {
        cursor: pointer;
      }
    }
    .error-message {
        color: red;
        font-size: 12px;
        margin-top: 5px;
        text-align: start;
    }
    .modal-text {
        font-size: 16px;
        text-align: start;
        margin-bottom: 10px;
        margin-top: 5px;
        font-weight: 325;
        line-height: 24px;
    }
    .line-items {
        margin-bottom: 40px;
        .line-item {
            display: flex;
            padding: 12px 16px;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid #D4D4D8;
            margin-bottom: 10px;

            &.disabled {
              background: #dddddd42;
              opacity: 0.7;
            }

            &.active {
            border: 1px solid #737373;
            }

            .item-name {
               padding-left: 26px;
               font-weight: 350;
               margin-top: 0;
            }
            .serial-number {
               padding-left: 26px;
               font-size: 12px;
               font-style: normal;
               color: #737373;
               margin-top: 0;
               text-align: start;
            }
            .note {
               padding-left: 26px;
               font-weight: 350;
               font-size: 12px;
               margin-top: 0;
               color: #737373;
               text-align: justify;
               .link {
                  color: $blue;
               }
            }
            .item-quantity-wrapper {
               display: flex;
               flex-direction: row;
               justify-content: space-between;
               align-items: center;
               width: 100%;

               .item-quantity-input {
                    width: 100px;
                    margin-left: 26px;
                    border: solid 1px #e8e8e8;
                    padding: 8px 4px;
                    text-align: center;
                    border-radius: $radius;
                    box-sizing: border-box;

                    &:focus-visible {
                        outline: 1px solid $blue
                    }
               }

               .item-price {
                    font-size: 12px;
                    color: #0A0A0A;
                    font-weight: 325;
               }
            }
        }
    }
    .shipping-address-wrapper {
        max-width: 550px;
        display: flex;
    }
    .refund-summary-container {
        max-width: 550px;
        .summary-heading {
            font-size: 16px;
            text-align: start;
        }

        .summary-item {
            display: flex;
            justify-content: space-between;
            margin-top: 8px;

            .note {
              font-size: 10px;
              color: #718096;
              text-align: start;
            }

            .item-label {
                display: flex;
                flex-direction: column;
                font-size: 14px;
                align-items: flex-start;

                span {
                    display: inline-block;
                }

                .item-description {
                    font-size: 10px;
                    color: #718096;
                    text-align: start;
                }

                &.bold {
                    font-weight: 700;
                }
            }

            .item-value {
                font-weight: 600;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                &.item-negative {
                    color: #e53e3e;
                }
            }

            .total-refund {
                 margin-top: 16px;
            }
        }
    }
    .error-message-wrapper {
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }

    .button-wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        button {
          display: inline-block;
        }

        &.center {
            justify-content: center;
        }
    }
  }
}


.stepper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  .circle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .circle {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #ddd;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &.active {
        background-color: $blue;
      }
    }
    .step-heading {
      position: absolute;
      margin-top: 80px;
      color: #ddd;
      &.active {
        color: #333333;
      }
    }
  }


    .line {
        height: 2px;
        width: 220px;
        background-color: #ddd;

        &.active {
            background-color: $blue;
        }
    }
}
