@import '../../../variables.scss';

.GenerateGiftCardForm {
  float: left;
  width: 100%;
  margin: 30px 0 0 0;
  .input-wrap {
    float: left;
    width: 59%;
    margin-right: 1%;
    @media (max-width: $breakpoint-tablet-v) {
      width: 100%;
      margin-right: 0;
    }
  }
  .button-wrap {
    float: left;
    width: 29%;
    margin-right: 1%;
    @media (max-width: $breakpoint-tablet-v) {
      width: 100%;
      margin-right: 0;
    }
  }
  .info-wrap {
    position: relative;
    float: left;
    width: 10%;
    @media (max-width: $breakpoint-tablet-v) {
      width: 100%;
    }
    .info {
      color: $blue;
      margin: 20px 10px;
      @media (max-width: $breakpoint-tablet-v) {
        float: left;
        width: 10%;
        margin: 5px;
        box-sizing: border-box;
      }
    }
    .tooltip {
      position: absolute;
      top: -50%;
      left: -50%;
      background: rgb(233, 233, 233);
      padding: 10px 26px;
      border-radius: $radius;
      font-size: 12px;
      width: 300px;
      height: 40px;
      transform: translate(-119px, -24px);
      opacity: 0;
      @media (max-width: $breakpoint-tablet-v) {
        position: relative;
        top: initial;
        left: initial;
        height: auto;
        width: 80%;
        padding: 5%;
        opacity: 1;
        float: left;
        transform: initial;
        box-sizing: border-box;
      }
      .arrow {
        position: absolute;
        bottom: -6px;
        left: 50%;
        margin-left: -6px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid rgb(233, 233, 233);
        @media (max-width: $breakpoint-tablet-v) {
          display: none;
        }
      }
    }
    &:hover {
      .tooltip {
        opacity: 1;
      }
    }
  }
}
