@import '../../variables.scss';
.right {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.language-selector {
  margin-left: auto;
  justify-self: center;
  position: relative;
  z-index: 1;
  @media (max-width: $breakpoint-tablet) {
    margin-right: 4rem;
  }
  button {
    padding: 0.355rem 6px;
    text-align: center;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: #354256;
    &:hover {
      // outline: 1px solid #298fc1;
      cursor: pointer;
    }
    span {
      &.icon {
        padding-right: 8px;
      }
      &.dropdown-icon {
        padding-left: 8px;
        svg {
          width: 0.5rem;
          height: 0.5rem;
        }
      }
    }
    svg {
      width: 1.3rem;
      height: 1.3rem;
    }
  }
  .menu {
    list-style: none;
    padding: 0;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.25rem;
    display: block;
    z-index: 1011;
    box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);

    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    top: 2.2rem;
    left: -110px;
    width: 14rem;
    border: 1px solid rgba(0, 0, 0, 0.1);

    li {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 0.9rem;
      &:hover {
        background-color: rgba(243, 244, 246, 0.6);
        cursor: pointer;
      }
      button {
        width: 100%;
      }
      svg {
        height: 24px;
        width: 24px;
        margin-right: 8px;
        border-radius: 4px;
      }
      .check-icon {
        display: block;
        margin-left: auto;
        svg {
          height: 1rem;
          width: 1rem;
          fill: $blue;
        }
      }
    }
  }
}
