@import '../../variables.scss';

.Input {
	float: left;
	position: relative;
	padding: 20px 4px 4px 4px;
	margin: 10px 0;
	border-radius: $radius;
	overflow: hidden;
	border: solid 1px #e8e8e8;
	width: 100%;
	background-color: white;
	box-sizing: border-box;
	cursor: text;
	text-align: left;
	label {
		position: absolute;
		top: 12px;
		left: 12px;
		height: 18px;
		line-height: 18px;
		font-size: 13px;
		color: #82888e;
		transition: all 150ms;
	}
	&.disabled {
		background-color: #D8D8D8;
	}
	&.small-placeholder {
		label {
			top: 2px;
			font-size: 10px;
		}
	}
	input {
		border: none;
		height: 22px;
		outline: none;
		text-indent: 8px;
		width: 100%;
		font-family: "gotham-book", "Montserrat", sans-serif;
		background-color: transparent;
		font-size: 13px;
	}
	&.bottom-line {
		border-radius: 0px;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid #D8D8D8;
		input {
			background-color: transparent;
		}
	}
	&.error {
		border: solid 1px $red;
		label {
			color: $red;
		}
		&.bottom-line {
			border: none;
			border-bottom: 1px solid $red;
		}
	}
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
	}
}
