@import '../../variables.scss';

.dropdown-button-container {
  position: relative;
  display: flex;
  justify-content: flex-end;

  .button {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: #1a202c;
    background-color: #ffffff;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border: none;
    cursor: pointer;
    margin: 10px;

    &:hover {
      background-color: #f6f9f6;
    }
  }

  .dropdown {
    position: absolute;
    right: 0;
    width: 10rem;
    margin-top: 0;
    z-index: 10;
    border-radius: 0.375rem;
    background-color: #ffffff;
    box-shadow: 0 8px 4px rgba(0,0,0,0.1);
    outline: none;
    border: 1px solid #82888e47;
    top: 45px;
    right: 20px;

    @media (max-width: $breakpoint-tablet-v) {
      right: 0px;
      left: -60px;
    }

    .form-button {
      width: 100%;
      padding: 0.5rem;
      font-size: 1rem;
      text-align: center;
      border: none;
      background-color: transparent;
      cursor: pointer;
      border-radius: 0.375rem;

      &:hover {
        background-color: #f6f9f6;
      }
    }
  }

  .dropdown_profile {
      position: absolute;
      right: 0;
      width: 13rem;
      margin-top: 0;
      z-index: 10;
      border-radius: 0.375rem;
      background-color: #ffffff;
      box-shadow: 0 8px 4px rgba(0,0,0,0.1);
      outline: none;
      border: 1px solid #82888e47;
      top: 45px;
      right: 20px;

      @media (max-width: $breakpoint-tablet-v) {
        right: -10px;
      }

      .form-button {
        width: 100%;
        padding: 0.8rem;
        font-size: 1rem;
        text-align: left;
        border: none;
        background-color: transparent;
        cursor: pointer;
        border-radius: 0.375rem;

        &:hover {
          background-color: #f6f9f6;
        }
      }
    }
}
