@use '../../../variables.scss';

.ScreenTitle {
  font-family: "gotham-bold";
  font-size: 21px;
  margin: 20px 0;
  text-transform: uppercase;
  color: #18232f;
  font-weight: 700;
  letter-spacing: 2px;
}
