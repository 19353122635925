@use '../../../variables.scss';

.ChangePasswordForm {
  margin-block-end: 2em;
  form {
    overflow: hidden;
    .password-wrap {
      float: left;
      position: relative;
      width: 100%;
      overflow: hidden;
      .eye-password {
        position: absolute;
        right: 12px;
        top: 26px;
        user-select: none;
        cursor: pointer;
        .eye-icon {
          color: rgba(black, 0.6);
        }
      }
    }
  }
}
