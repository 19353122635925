// Variables
$blue: #197c80;
$green: #197c80;
$red: #FF4B50;
$cyan: #27D2D9;
$mid-gray: #82888e;

$radius: 4px;
$radius-large: 20px;
$hover-opacity: 0.85;
$shadow: 0px 0px 1px 2px lighten(black, 95%);

$breakpoint-tablet: 1200px;
$breakpoint-tablet-v: 800px;

@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }
  #{$property}: $value;
}
