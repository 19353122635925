@import '../../../variables.scss';

.download-label {
	float: left;
	width: 100%;
	background-color: lighten(black, 97%);
	padding: 20px;
	border-radius: $radius;
	box-sizing: border-box;
	margin: 10px 0;
	.download-label-inner {
		float: left;
		width: 100%;
		overflow: hidden;
		background-color: white;
		box-shadow: $shadow;
		border-radius: $radius;
		box-sizing: border-box;
		padding: 20px;
		.download-label-heading {
			float: left;
			width: 100%;
			.tag {
				float: left;
				border-radius: 100px;
				color: white;
				text-decoration: none;
				text-transform: uppercase;
				font-size: 14px;
				font-weight: 700;
				padding: 10px 20px;
				margin: 10px 10px 10px 0;
				letter-spacing: 1px;
				background-color: #2a8dc3;
			}
		}
		.download-label-body {
			float: left;
			width: 100%;
			display: table;
			vertical-align: middle;
			.circle-wrap {
				display: inline-block;
				width: 56px;
				height: 56px;
				border: solid 10px lighten($blue, 12%);
				background-color: lighten($blue, 1%);
				border-radius: 50%;
				margin: 30px 0px;
				.circle {
					position: relative;
					width: 56px;
					height: 56px;
					display: table-cell;
					vertical-align: middle;
					img {
						max-height: 100%;
						max-width: 100%;
						width: auto;
						height: auto;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						margin: auto;
					}
				}
			}
			.link-wrap {
				display: table-cell;
				vertical-align: middle;
				p {
					font-size: 12px;
					margin: 10px;
				}
			}
		}
	}
}
