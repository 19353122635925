.block.DefaultLoader {
  display: block;
}
.white.DefaultLoader {
    border-top: 1.1em solid #fff;
    border-right: 1.1em solid #fff;
    border-bottom: 1.1em solid #fff;
    border-left: 1.1em solid #197c80;
}
.secondary.DefaultLoader {
    border-top: 1.1em solid #197c80;
    border-right: 1.1em solid #197c80;
    border-bottom: 1.1em solid #197c80;
    border-left: 1.1em solid #ececec;
}
.DefaultLoader,
.DefaultLoader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.DefaultLoader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
