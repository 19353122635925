@import '../../../variables.scss';

.AutopayDetailSegment {
    display: none;
    float: left;
    position: relative;
    width: 100%;
    padding: 20px;
    margin: 40px 0;
    border-radius: $radius;
    box-sizing: border-box;
    &.show {
        display: block;
    }
    p {
        font-size: 12px;
        margin: 2px 0;
    }
    .details {
        margin: 15px 0;
    }
    .red {
        color: #F25F5C;
    }
    .close {
        position: absolute;
        font-size: 24px;
        float: right;
        right: 29px;
        top: 33px;
        color: #82888E;
    }
    h3 {
        color: $blue;
        font-weight: 600;
        font-size: 18px;
    }
    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0px 30px;
        tr:last-child td {
            border-top: solid 1px #D9D9D9;
        }
        td {
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 10px 10px 0 10px;
            &:nth-child(2) {
                width: auto;
            }
            &:nth-child(3) {
                text-align: right;
            }
            h4 {
                color: #4D596B;
                letter-spacing: 1px;
                font-size: 14px;
                margin: 2px 0;
            }
            p {
                font-size: 14px;
                color: #4D596B;
                margin: 2px 0;
            }
            a, span {
                color: #4D596B;
                font-size: 14px;
                text-decoration: none;
                font-weight: 500;
            }
            .price {
                font-size: 12px;
                color: #4D596B;
                font-weight: 600;

            }
        }
    }
}
