@use 'variables.scss';
.react-datepicker {
    border: 1px solid $cyan!important;
}
.react-datepicker__header {
    background-color: $cyan!important;
    border-bottom: 1px solid $cyan!important;
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        color: white !important;
    }
}
.react-datepicker__current-month, .react-datepicker-time__header {
    color: white !important;
}

.react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: white !important;
    outline: none !important;
}
.react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: white !important;
    outline: none !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    background-color: $cyan!important;
}
