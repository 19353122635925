@import '../../../variables.scss';

.Message {
	float: left;
	border-radius: $radius;
	box-sizing: border-box;
	padding: 16px 10px;
	margin: 10px 0;
	display: table;
	.icon {
		display: table-cell;
		vertical-align: middle;
		width: 30px;
		height: 30px;
		text-align: center;
	}
	.text {
		display: table-cell;
		vertical-align: middle;
		font-size: 14px;
		h4 {
			margin: 0 10px;
			font-size: 12px;
			color: $blue;
		}
		p {
			font-size: 12px;
			margin: 0 10px;
			padding: 0;
			color: #4D596B;
		}
		a {
			color: #4D596B;
		}
	}
	&.success {
		background-color: rgba($green, 0.2);
		border: solid 1px $green;
		.icon {
			color: $green;
		}
	}
	&.success-light {
		background-color: rgba($green, 0.2);
		border: solid 1px $green;
		.icon {
			color: $green;
		}
	}
	&.error {
		background-color: rgba($red, 0.2);
		border: solid 1px $red;
		.icon {
			color: $red;
		}
	}
	&.error-light {
		background-color: rgba($red, 0.2);
		border: solid 1px $red;
		.icon {
			color: $red;
		}
	}
	&.info {
		background-color: #ffffff;
		border: none;
		border-left: 1px solid $blue;
    	border-radius: 0;
		.icon {
			color: $blue;
		}
	}
	&.full-width {
		width: 100%;
	}
}
